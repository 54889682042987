'use strict'

###*
 # @ngdoc service
 # @name mundoComponents.factory:ConfirmBox

 # @description

###
angular
  .module 'mundoComponents'
  .factory 'ConfirmBox', [
    '$mdDialog'
    ($mdDialog) ->
      ConfirmBoxBase = {}
      ###
      title = box title
      message = box message
      ###
      ConfirmBoxBase.showConfirmDialog = (title, message) ->
        $mdDialog.show({
          templateUrl: 'mundo-components/mundo-confirm-box/views/mundo-confirm-box.modal.tpl.html'
          controller: 'MundoConfirmBoxCtrl'
          controllerAs: 'confirmBoxCtrl'
          clickOutsideToClose: false
          locals: {
            title: title
            message: message
          }
        })

      ConfirmBoxBase
  ]

